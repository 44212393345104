import {
    ISERRORSERVER,
    RESET
} from '../constants/App';

const initialState = {
    fetching: false,
    isError:false,
    details:{}
};

export default function errorReducer(state = initialState, action = {}) {
    switch (action.type) {
        case ISERRORSERVER:
            return {
                ...state,
                fetching: false,
                isError:true,
                details:action.data
            };
        case RESET:
            return {
                fetching: false,
                isError:false,
                details:{}
            };
        default:
            return state;
    }
}